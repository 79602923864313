export const CHANGE_LIKES = "likes/CHANGE_LIKES";
export const REMOVE_LIKES = "likes/REMOVE_LIKES";

const initialState = {
  likes: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_LIKES:
      return {
        ...state,
        likes: {
          ...state.likes,
          [action.payload.id]: {
            numberOfLikes: action.payload.numberOfLikes,
          },
        },
      };

    case REMOVE_LIKES:
      return {
        ...state,
        likes: {
          ...state.likes,
          [action.payload.id]: null,
        },
      };

    default:
      return state;
  }
}
