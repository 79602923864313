import React from "react"
import {BrowserRouter as Router, Route} from "react-router-dom"

import Landing from "../pages/Landing"
import Privacy from "../pages/Privacy"
import Player from "../pages/Player"

export default function Navigation() {
  return (
    <Router>
      <Route exact path="/" component={Landing} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/videos/:videoId" component={Player} />
    </Router>
  )
}
