import {connect} from "react-redux"

import * as likesActions from "../../shared/redux/likes"
import * as playerActions from "./redux/player"
import * as commentsActions from "../../shared/redux/comments"

import Player from "./components/Player"

const mapStateToProps = (state) => {
  return {
    likes: state.likes,
    player: state.player,
    comments: state.comments,
  }
}

const mapDispatchToProps = {
  ...likesActions,
  ...playerActions,
  ...commentsActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Player)
