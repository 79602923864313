import {db} from "../../../shared/firebaseConfig"
import {subscribeToLikesForId} from "../../../shared/helpers/likes"
import {subscribeToCommentsForId} from "../../../shared/helpers/comments"

const LOAD_VIDEO = "feed/LOAD_VIDEO"

function subscribeForLikesAndComments(videoId, dispatch) {
  // TODO: kill the listener when changing the video.
  subscribeToLikesForId(videoId, dispatch)
  subscribeToCommentsForId(videoId, dispatch)
}

export function loadVideo(videoId) {
  return (dispatch) => {
    db.ref(`/videos/${videoId}`)
      .once("value")
      .then((snapshot) => {
        const video = snapshot.val()

        subscribeForLikesAndComments(videoId, dispatch)

        dispatch({
          type: LOAD_VIDEO,
          payload: {
            videoId,
            video,
          },
        })
      })
  }
}

const initialState = {
  videos: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_VIDEO:
      return {
        ...state,
        videos: {
          ...state.videos,
          [action.payload.videoId]: action.payload.video,
        },
      }

    default:
      return state
  }
}
