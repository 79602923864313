import React from "react"

import "./styles.css"

export default function ShareButton({onClick, numberOfTimesShared, videoId}) {
  return (
    <div className="share-button--container" onClick={onClick}>
      <svg width="36" height="36" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M27.7552 6.97579L20.1719 -0.89912C19.9245 -1.15568 19.5454 -1.23621 19.2153 -1.10334C18.884 -0.969188 18.667 -0.648329 18.667 -0.291369V3.5004H18.3754C12.1035 3.5004 7.00049 8.60337 7.00049 14.8753V16.6252C7.00049 17.0311 7.28631 17.3695 7.68172 17.4618C7.74602 17.478 7.81011 17.4851 7.8742 17.4851C8.20445 17.4851 8.52061 17.2913 8.67335 16.9869C10.3137 13.7051 13.6118 11.6669 17.281 11.6669H18.667V15.4585C18.667 15.8156 18.884 16.1365 19.2153 16.2694C19.5432 16.4035 19.9245 16.3219 20.1719 16.0651L27.7552 8.19022C28.0819 7.85078 28.0819 7.31652 27.7552 6.97579Z"
            fill="#eee"
          />
          <path
            d="M24.5002 26.8332H3.50045C1.57081 26.8332 0.000488281 25.2631 0.000488281 23.3333V7.00027C0.000488281 5.07063 1.57081 3.50031 3.50045 3.50031H7.00041C7.64554 3.50031 8.16699 4.02175 8.16699 4.66689C8.16699 5.31202 7.64554 5.83347 7.00041 5.83347H3.50045C2.85638 5.83347 2.33365 6.3562 2.33365 7.00027V23.3333C2.33365 23.9773 2.85638 24.5001 3.50045 24.5001H24.5002C25.1441 24.5001 25.6668 23.9773 25.6668 23.3333V14.0002C25.6668 13.3551 26.1882 12.8334 26.8334 12.8334C27.4787 12.8334 28.0002 13.3551 28.0002 14.0002V23.3333C28.0002 25.2631 26.4298 26.8332 24.5002 26.8332Z"
            fill="#eee"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="28" height="28" fill="#eee" />
          </clipPath>
        </defs>
      </svg>

      <p className="share-button--text">{numberOfTimesShared}</p>
    </div>
  )
}
