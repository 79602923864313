import Firebase from "firebase/app";
import "firebase/database"; // for realtime database

const firebaseConfig = {
  apiKey: "AIzaSyA6unWrJReAzrhcQb_LqRFfX-hwglRDjuw",
  authDomain: "five-mins-ai.firebaseapp.com",
  databaseURL: "https://five-mins-ai.firebaseio.com",
  projectId: "five-mins-ai",
  storageBucket: "five-mins-ai.appspot.com",
  messagingSenderId: "101498540356",
  appId: "1:101498540356:web:9115ffd1d0b14a1d44c711",
  measurementId: "G-PPPSKPT82T",
};

const app = Firebase.initializeApp(firebaseConfig);
export const db = app.database();
