import { db } from "../firebaseConfig";
import { CHANGE_LIKES, REMOVE_LIKES } from "../redux/likes";

export function subscribeToLikesForId(id, dispatch) {
  const likesRef = db.ref(`/likes/${id}`);

  // TODO: pagination
  likesRef.on("value", (likeSnapshot) => {
    const likes = likeSnapshot.val();

    if (likes) {
      dispatch({
        type: CHANGE_LIKES,
        payload: {
          id,
          // TODO: get the amount of items without getting all the likes.
          numberOfLikes: Object.keys(likes).length,
        },
      });
    } else {
      dispatch({
        type: REMOVE_LIKES,
        payload: {
          id,
        },
      });
    }
  });

  return likesRef;
}
