import React from "react"

import "./styles.css"

export default function CommentButton({numberOfComments, onClick}) {
  return (
    <div className="comment-button--container">
      <svg width="34" height="36" viewBox="0 0 48 40" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 28.44V5.37709C0 2.40764 2.40764 0 5.37818 0L42.6229 0.0774547C45.5924 0.0774547 48 2.48509 48 5.45455V28.5175C48 31.4869 45.5924 33.8945 42.6229 33.8945H34.9091V45.8945L24 33.8945L5.37709 33.8171C2.40764 33.8171 0 31.4095 0 28.44ZM15.2727 17.4382C15.2727 19.2457 13.8075 20.7109 12 20.7109C10.1925 20.7109 8.72727 19.2457 8.72727 17.4382C8.72727 15.6307 10.1925 14.1655 12 14.1655C13.8075 14.1655 15.2727 15.6307 15.2727 17.4382ZM24 20.7109C25.8075 20.7109 27.2727 19.2457 27.2727 17.4382C27.2727 15.6307 25.8075 14.1655 24 14.1655C22.1925 14.1655 20.7273 15.6307 20.7273 17.4382C20.7273 19.2457 22.1925 20.7109 24 20.7109ZM39.2727 17.4382C39.2727 19.2457 37.8075 20.7109 36 20.7109C34.1925 20.7109 32.7273 19.2457 32.7273 17.4382C32.7273 15.6307 34.1925 14.1655 36 14.1655C37.8075 14.1655 39.2727 15.6307 39.2727 17.4382Z"
          fill="#eee"
        />
      </svg>

      <p className="comment-button--text">{numberOfComments}</p>
    </div>
  )
}
