import React from "react"

import "./styles.css"

export default function LikeButton({onLike, numberOfLikes}) {
  return (
    <div className="like-button--container" onClick={onLike}>
      <svg width="34" height="36" viewBox="0 0 48 40" fill="none">
        <g>
          <path
            d="M35.25 2.8125C32.6453 2.8125 30.2573 3.63787 28.1525 5.26575C26.1345 6.82641 24.791 8.81419 24 10.2596C23.209 8.81409 21.8655 6.82641 19.8475 5.26575C17.7427 3.63787 15.3547 2.8125 12.75 2.8125C5.48138 2.8125 0 8.75784 0 16.6419C0 25.1595 6.83841 30.9871 17.1908 39.8092C18.9488 41.3075 20.9415 43.0057 23.0126 44.8168C23.2856 45.0559 23.6362 45.1875 24 45.1875C24.3638 45.1875 24.7144 45.0559 24.9874 44.8169C27.0587 43.0055 29.0513 41.3074 30.8103 39.8083C41.1616 30.9871 48 25.1595 48 16.6419C48 8.75784 42.5186 2.8125 35.25 2.8125Z"
            fill="#eee"
          />
        </g>
      </svg>

      <p className="like-button--text">{numberOfLikes}</p>
    </div>
  )
}
