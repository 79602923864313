import * as React from "react"

export const PAUSE_BUTTON_DEFAULT_SIZE = 65

export default function PlayButton({size = PAUSE_BUTTON_DEFAULT_SIZE, color = "#fff"}) {
  return (
    <svg width={size} height={size} viewBox="0 0 200 200" fill="none">
      <path
        d="M18 28.541c0-12.497 13.687-20.17 24.348-13.65l116.835 71.46c10.203 6.24 10.203 21.058 0 27.298L42.348 185.108C31.687 191.629 18 183.956 18 171.459V28.541z"
        fill={color}
      />
    </svg>
  )
}
