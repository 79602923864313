/* @flow */
import {combineReducers} from "redux"

import likes from "../shared/redux/likes"
import player from "../pages/Player/redux/player"
import comments from "../shared/redux/comments"

export default combineReducers({
  likes,
  player,
  comments,
})
