import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import {BrowserRouter as Router} from "react-router-dom"

import * as serviceWorker from "./serviceWorker"

import configureStore from "./store"
import Navigation from "./shared/Navigation"

import "./index.css"

ReactDOM.render(
  <Router>
    <Provider store={configureStore()}>
      <Navigation />
    </Provider>
  </Router>,
  document.getElementById("root"),
)

serviceWorker.unregister()
