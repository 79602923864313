import React from "react"

import "./styles.css"

export default function Comments({comments: commentData}) {
  let comments = commentData ? Object.values(commentData)[0] : []
  if (comments.length > 1) {
    delete comments[1].id
    comments = Object.values(comments[1])
  }

  return (
    <div className="comments--container">
      <p className="comments--title">Comments</p>

      {!comments.length && (
        <div className="comments--no-comments-container">
          <p className="comments--no-comments">Nothing here yet!</p>
        </div>
      )}

      {comments.map((comment, i) => (
        <div key={i} className="comments--comment">
          <img alt="profileImage" className="comments--profile-pic" src={comment?.author?.photoURL} />

          <div className="comments--text-container">
            <p className="comments--author-name">{comment?.author?.name}</p>
            <p className="comments--text">{comment?.text}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
