export const CHANGE_COMMENTS = "comments/CHANGE_COMMENTS";
export const CHANGE_NUMBER_OF_COMMENTS = "comments/CHANGE_NUMBER_OF_COMMENTS";

const initialState = {
  comments: {},
  numberOfComments: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.id]: {
            comments: action.payload.comments,
          },
        },
      };

    case CHANGE_NUMBER_OF_COMMENTS:
      return {
        ...state,
        numberOfComments: {
          ...state.numberOfComments,
          [action.payload.id]: {
            numberOfComments: action.payload.numberOfComments,
          },
        },
      };

    default:
      return state;
  }
}
