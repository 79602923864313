/* @flow */
import React from "react"

import "./style.css"
import banner from "./img/banner.png"
import device from "./img/devicehires.png"
import background from "./img/background.png"

const Landing = () => {
  return (
    <div className="landing-container">
      <img src={banner} alt="banner" className="banner" />

      <div className="content">
        <div className="text">
          <div className="title">
            <h1>We are changing the way you learn.</h1>
          </div>
          <div className="paragraph">
            <p>
              We're a fun and effective micro learning platform, that aims to make you a better professional
              as a whole.
            </p>
          </div>
        </div>

        <div className="deviceContainer">
          <img src={background} alt="device" className="background" />
          <img src={device} alt="device" className="device" />
        </div>
      </div>
    </div>
  )
}

export default Landing
