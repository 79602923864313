import React, {useEffect, useState} from "react"
import ReactPlayer from "react-player"

import "./styles.css"
import Comments from "../Comments"
import LikeButton from "../../../../shared/components/LikeButton"
import CommentButton from "../../../../shared/components/CommentButton"
import ShareButton from "../../../../shared/components/ShareButton"
import PlayButton from "../../../../shared/components/PlayButton"

export default function Player({
  match,
  loadVideo,
  likes: {likes},
  player: {videos},
  comments: {comments, numberOfComments},
}) {
  const {
    params: {videoId},
  } = match

  const [playing, setPlaying] = useState(false)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    loadVideo(videoId)
  }, [loadVideo, videoId])

  const onReady = () => {
    setReady(true)
  }

  const playVideo = () => {
    if (ready) {
      setPlaying(true)
    }
  }

  const pauseVideo = () => {
    if (playing) {
      setPlaying(false)
    }
  }

  const video = videos[videoId]

  return (
    <div className="player--container">
      <div className="player--dialog">
        <div className="player--section player--video-container" onClick={pauseVideo}>
          <ReactPlayer
            url={video && video.url}
            loop
            onReady={onReady}
            playing={playing}
            width={900}
            height={900}
            className="player--video"
          />
          {!playing && (
            <div className="player--play-button" onClick={playVideo}>
              <PlayButton />
            </div>
          )}

          <div className="player--reactions-container">
            <LikeButton numberOfLikes={likes[videoId] ? likes[videoId].numberOfLikes : 0} />

            <CommentButton
              numberOfComments={numberOfComments[videoId] ? numberOfComments[videoId].numberOfComments : 0}
            />

            <ShareButton numberOfTimesShared={30} />
          </div>
        </div>

        <div className="player--section player--comments-container">
          <Comments comments={comments[videoId]} numberOfComments={numberOfComments} />
        </div>
      </div>
    </div>
  )
}
