import { db } from "../firebaseConfig";
import { CHANGE_COMMENTS, CHANGE_NUMBER_OF_COMMENTS } from "../redux/comments";

export function subscribeToCommentsForId(videoId, dispatch) {
  const commentsRef = db.ref(`/comments/${videoId}`);

  // TODO: pagination
  commentsRef.orderByChild("datetime").on("value", (commentSnapshot) => {
    const commentsVal = commentSnapshot.val();

    if (commentsVal) {
      const commentsKeys = Object.keys(commentsVal);
      const comments = commentsKeys.map((commentKey) => {
        return {
          ...commentsVal[commentKey],
          id: commentKey,
        };
      });

      dispatch({
        type: CHANGE_COMMENTS,
        payload: {
          id: videoId,
          comments,
        },
      });

      dispatch({
        type: CHANGE_NUMBER_OF_COMMENTS,
        payload: {
          id: videoId,
          numberOfComments: commentsKeys.length,
        },
      });
    }
  });

  return commentsRef;
}
